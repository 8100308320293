import { lazy } from 'react';

// project imports
import AuthGuard from 'utils/route-guard/AuthGuard';
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';

// sample page routing
const SamplePage = Loadable(lazy(() => import('views/sample-page')));
const Cidade = Loadable(lazy(() => import('views/municipio')));
const Ramo = Loadable(lazy(() => import('views/cpramo')));
const Taxa = Loadable(lazy(() => import('views/cptaxa')));
const Operadora = Loadable(lazy(() => import('views/operadora')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/',
            element: <SamplePage />
        },
        {
            path: '/sample-page',
            element: <SamplePage />
        },
        {
            path: '/cidade',
            element: <Cidade />
        },
        {
            path: '/ramo',
            element: <Ramo />
        },
        {
            path: '/taxa',
            element: <Taxa />
        },
        {
            path: '/operadora',
            element: <Operadora />
        }
    ]
};

export default MainRoutes;
