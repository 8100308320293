/**
 * axios setup to use mock service
 */

import axios from 'axios';

const axiosServices = axios.create({
    baseURL: process.env.REACT_APP_MAIN_API_URL
});

// interceptor for request
axiosServices.interceptors.request.use(
    (request) => {
        const serviceToken = localStorage.getItem('serviceToken');

        if (serviceToken && request.url !== '/refresh') {
            request.headers.Authorization = `Bearer ${serviceToken}`;
        }
        return request;
    },
    (error) => Promise.reject((error.response && error.response.data) || 'Wrong Services')
);

// interceptor for response
axiosServices.interceptors.response.use(
    (response) => response,
    (error) => Promise.reject((error.response && error.response.data) || 'Wrong Services')
);

export default axiosServices;
