// third-party
// import { FormattedMessage } from 'react-intl';

// assets
import { IconBrandChrome, IconHelp, IconSitemap, IconBuilding } from '@tabler/icons';

// constant
const icons = {
    IconBrandChrome,
    IconHelp,
    IconSitemap,
    IconBuilding
};

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const master = {
    id: 'sample-docs-roadmap',
    title: 'Funções Master',
    type: 'group',
    children: [
        {
            id: 'cidade',
            title: 'Cidades',
            type: 'item',
            url: '/cidade',
            icon: icons.IconBuilding,
            breadcrumbs: false
        },
        {
            id: 'ramo',
            title: 'Ramos',
            type: 'item',
            url: '/ramo',
            icon: icons.IconBuilding,
            breadcrumbs: false
        },
        {
            id: 'taxa',
            title: 'Taxas Básicas',
            type: 'item',
            url: '/taxa',
            icon: icons.IconBuilding,
            breadcrumbs: false
        },
        {
            id: 'operadora',
            title: 'Operadoras',
            type: 'item',
            url: '/operadora',
            icon: icons.IconBuilding,
            breadcrumbs: false
        }
    ]
};

export default master;
