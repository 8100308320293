import master from './master';
import other from './other';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
    items: [master, other]
};

export default menuItems;
